export default {
    name: "table-usuarios",
    ignorePersonalizar: true,
    novoRoute: 'painel-admin-users-new',
    novoText: 'Novo Usuário',
    columns: [
        { key: 'edit_id', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'usu_name', label: 'Usuário', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'usu_email', label: 'Email', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'usu_data_cad', label: 'Data cadastro', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    items: [],
}