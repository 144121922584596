<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg10 xl10">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(name)="{ source: name }">
            {{ name }}
          </template>
          <template #cell(status)="{ source: status }">
            <va-chip color="primary" v-if="status.statusName === 'Processando'">{{ status.statusName }}</va-chip>
            <va-chip color="#ccc" v-else-if="status.statusName === 'Aguardando'">{{ status.statusName }}</va-chip>
            <va-chip color="#FFAC0A" v-else-if="status.statusName === 'Pausado'">{{ status.statusName }}</va-chip>
            <va-chip color="success" v-else-if="status.statusName === 'Completo'">{{ status.statusName }}</va-chip>
            <va-progress-bar style="margin-top: 5px;"
              v-if="status.statusName === 'Processando'"
              size="large"
              :model-value="status.percent < 10 ? 15 : status.percent"
            >
              {{ status.percent > 0 ? ((status.percent * 100) / 100) + '%' : '0%' }}
            </va-progress-bar>
          </template>
          <template #cell(start_date)="{ source: start_date }">
            {{ formatDate(start_date, 'YYYYMMDD','L') }}
          </template>
          <template #cell(shipments)="{ source: shipments }">
            {{ shipments }}
          </template>
          <template #cell(conversions)="{ source: conversions }">
            {{ conversions }}
          </template><template #cell(cancellations)="{ source: cancellations }">
            {{ cancellations }}
          </template>
          <template #cell(details_id)="{ source: details_id }">
            <va-button icon="visibility" gradient @click="detailsCampaign(details_id, this)"></va-button>
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import table from "./HistoricTable"
import {refresh, detailsCampaign} from "./HistoricFunctions";
import {formatDate} from "@/functions/Formatters"

export default {
  name: "Historic",
  data() {
    return {
      table
    }
  },
  methods: {
    refresh,
    detailsCampaign,
    formatDate
  }
}
</script>

<style scoped>

</style>