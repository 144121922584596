<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md9 lg9 xl9">
      <va-card-content>
        <!--        <div class="row">-->
        <!--          <div class="flex xs12 sm12 md6 lg6 xl6 mb-4">-->
        <ContactList></ContactList>
        <!--          </div>-->
        <!--          <div class="flex xs12 sm12 md6 lg6 xl6">-->
        <!--            <AddContact></AddContact>-->
        <!--          </div>-->
        <!--        </div>-->
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import ContactList from "./contacts/ContactList";
export default {
  name: "EditList",
  components:{
    ContactList
  }
}
</script>

<style scoped>

</style>