<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg10 xl10">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(created_date)="{ source: created_date }">
            {{ formatDate(created_date, 'YYYY-MM-DD H:m:i','LLL') }}
          </template>
          <template #cell(edit_id)="{ source: edit_id }">
            <va-button icon="edit" gradient @click="editList(edit_id, this)"></va-button>
          </template>
          <template #cell(delete_id)="{ source: delete_id }">
            <va-button icon="delete" color="danger" gradient @click="lastDeleteModalID = delete_id; deleteModal = true"></va-button>
          </template>
          <template #cell(exportar)="{ source: exportar }">
            <va-button color="info" gradient @click="exportarCsv(this, exportar)">
              Exportar CSV
            </va-button>
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
    <va-modal v-model="deleteModal" message="Deseja realmente excluir esta lista?" ok-text="Excluir" @ok="deleteList(lastDeleteModalID, this)" />
  </div>
</template>

<script>
import table from "./ListsTable";
import {refresh, editList, deleteList, exportarCsv} from "./ListsFunctions"
import {formatDate} from "@/functions/Formatters"

export default {
  name: "Lists",
  data() {
    return {
      table,
      deleteModal: false,
      lastDeleteModalID: false
    }
  },
  methods:{
    refresh,
    formatDate,
    editList,
    deleteList,
    exportarCsv
  },
}
</script>

<style scoped>

</style>