<template>
  <div style="text-align: center; width: 100%">
    <va-button color="success" gradient @click="salvarLista(this)">
      Salvar
    </va-button>&nbsp;
    <va-button color="info" gradient @click="exportarCsv(this)">
      Exportar CSV
    </va-button>
  </div>
  <br>
  <va-data-table
    :no-data-html="'Sem contatos'"
    :items="items"
    :columns="columns"
    :hoverable="true"
    :striped="true"
    sticky-header
    :wrapper-size="500"
    :virtual-scroller="virtualScroll"
  >
    <template #headerAppend>
      <tr class="table-example--slot">
        <th
          v-for="key in Object.keys(novo)"
          :key="key"
          colspan="1"
        >
          <va-input
            :placeholder="key"
            v-model="novo[key]"
          />
        </th>
        <th colspan="1">
          <va-button
            @click="addNewItem()"
          >
            +
          </va-button>
        </th>
      </tr>
    </template>
    <template #cell(nome)="{ source: nome }">
      {{ nome }}
    </template>
    <template #cell(numero)="{ source: numero }">
      {{ numero }}
    </template>
    <template #cell(data)="{ source: data }">
      {{ data }}
    </template>
    <template #cell(actions)="{ rowIndex }">
      <va-button flat icon="delete" color="danger" gradient @click="deleteItemById(rowIndex)" />
    </template>
  </va-data-table>
</template>

<script>
import EditListTable from "./ContactListTable";
import {getListContact, salvarLista, exportarCsv} from "./ContactListFunctions"

const defaultItem = {
  name: '',
  number: '',
}

export default {
  name: "EditList",
  async mounted() {
    this.id = this.$route.params.id
    await getListContact(this)
  },
  data() {
    return {
      columns: EditListTable.columns,
      items: [],
      name: false,
      id: false,
      novo: defaultItem,
      virtualScroll: true
    }
  },
  methods: {
    getListContact,
    salvarLista,
    exportarCsv,
    deleteItemById (id) {
      this.items = [
        ...this.items.slice(0, id),
        ...this.items.slice(id + 1),
      ]
    },
    addNewItem () {
      this.items = [...this.items, { ...this.novo }]
      this.resetCreatedItem()
    },
    resetCreatedItem () {
      this.novo = { ...defaultItem }
    },
  },
}
</script>

<style lang="scss">
.table-example--slot {
th {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
}
td.va-data-table__table-td{
  white-space: normal!important;
}
</style>