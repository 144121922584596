export default {
    name: "table-listas",
    ignorePersonalizar: true,
    novoRoute: 'painel-lists-new',
    novoText: 'Nova Lista',
    columns: [
        { key: 'exportar', label: 'Exportar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'name', label: 'Nome da Lista', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'created_date', label: 'Data', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'quantity', label: 'Leads', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'edit_id', label: 'Editar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'delete_id', label: 'Excluir', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    items: [],
}