import {FetchWs} from "@/functions/FetchWs";

export async function salvarLista( vue: any ) {

    const contacts = JSON.stringify({
        contacts: vue.items
    })
    const body = new FormData();
    body.append("name", vue.listName);
    body.append("contacts", contacts);
    const response = await FetchWs('list/new', 'POST',body);
    if(response?.success) {
        window.history.back();
    }
}
