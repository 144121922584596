import {ContactListInterfaces} from "./ContactListInterfaces"
import {FetchWs} from "@/functions/FetchWs";

export async function getListContact(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('list/list-details/' + vue.id);
    if(response && response.success){
        vue.name = response.data.name
        vue.items = assertContactList(response.data.contacts);
        vue.virtualScroll = vue.items.length > 8
    }
}

function assertContactList(objs: ContactListInterfaces[]): ContactListInterfaces[]
{
    objs.forEach((value:ContactListInterfaces, key:number) => {
        objs[key].name = value.name
        objs[key].number = value.number
        objs[key].actions = value.id
    })

    return objs;
}
export async function salvarLista( vue: any ) {

    const contacts = JSON.stringify({
        contacts: vue.items
    })
    const body = new FormData();
    body.append("name", vue.name);
    body.append("contacts", contacts);
    const response = await FetchWs('list/update/' + vue.id, 'POST',body);
    if(response?.success) {
        window.history.back();
    }
}

export async function exportarCsv( vue: any ) {

    const items: Array<{name: string, number: string}> = vue.items
    const csvString = [
        [
            "Nome",
            "WhatsApp"
        ],
        ...items.map((item) => [
            item.name,
            item.number
        ])
    ]
    .map(e => e.join(","))
    .join("\n");
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', 'Leads.csv');
    a.click()
}
