<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg10 xl10">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(criado)="{ source: criado }">
            {{ formatDate(criado, 'YYYY-MM-DD H:m:i','LLL') }}
          </template>
          <template #cell(status)="{ source: status }">
            <va-chip class="chip__status" color="#ccc" v-if="status.status === 'AGUARDANDO'">Aguardando</va-chip>
            <va-chip class="chip__status" color="success" v-else-if="status.status === 'CONCLUIDO'">Finalizado</va-chip>
            <va-chip class="chip__status" color="#ccc" v-else-if="status.status === 'PAUSADO'">Pausado</va-chip>
            <va-chip v-else class="chip__status" style="margin-bottom: 5px">Processando</va-chip>
            <va-progress-bar
              v-if="status.status !== 'AGUARDANDO' && status.status !== 'CONCLUIDO' && status.status !== 'PAUSADO'"
              :model-value="status.porcentagem"
              size="large"
            >
              {{ status.porcentagem + '%' }}
            </va-progress-bar>
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import table from "./HistoricTable"
import {
  refresh,
} from "./HistoricFunctions";
import {formatDate} from "@/functions/Formatters";

export default {
  name: "Historic",
  data() {
    return {
      table
    }
  },
  methods: {
    refresh,
    formatDate
  }
}
</script>


<style scoped>

.btn-dropdown{
  min-width: 300px;
  margin-bottom: 3px;
}

</style>
<style>
.select__lists .va-modal__dialog{
  overflow: visible;
}
</style>