<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg10 xl10">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(status)="{ source: status }">
            <va-chip color="primary" v-if="status.statusName === 'Processando'">{{ status.statusName }}</va-chip>
            <va-chip color="#ccc" v-else-if="status.statusName === 'Aguardando'">{{ status.statusName }}</va-chip>
            <va-chip color="#FFAC0A" v-else-if="status.statusName === 'Pausado'">{{ status.statusName }}</va-chip>
            <va-chip color="success" v-else-if="status.statusName === 'Completo'">{{ status.statusName }}</va-chip>
            <va-progress-bar style="margin-top: 5px;"
              v-if="status.statusName === 'Processando'"
              size="large"
              :model-value="status.percent < 10 ? 15 : status.percent"
            >
              {{ status.percent > 0 ? ((status.percent * 100) / 100) + '%' : '0%' }}
            </va-progress-bar>
          </template>
          <template #cell(start_date)="{ source: start_date }">
            {{ formatDate(start_date, 'YYYYMMDD','L') }}
          </template>
          <template #cell(edit_id)="{ source: edit_id }">
            <va-button icon="visibility" class="" @click="acoesModal = edit_id"/>
          </template>
        </monta-listagem>
        <va-modal v-model="acoesModal">
          <div>
            <va-button class="btn-dropdown" color="info" gradient icon-right="download" @click="exportarCsv(this, acoesModal)">
              Exportar CSV
            </va-button><br>
            <va-button class="btn-dropdown" icon-right="delete" color="danger" gradient @click="deleteModal = lastDeleteModalID = acoesModal;acoesModal = false;" size="medium">Excluir</va-button><br>
            <va-button v-if="isComplete(acoesModal, this)" class="btn-dropdown" icon-right="play_arrow" color="warning" gradient @click="pausePlay(this, acoesModal);acoesModal = false;" size="medium">Pausar / Processar</va-button><br>
            <va-button v-if="isComplete(acoesModal, this)" class="btn-dropdown" icon-right="edit" color="secondary" gradient @click="editCam(acoesModal, this)" size="medium">Editar</va-button>
          </div>
        </va-modal>
        <va-modal v-model="deleteModal" message="Deseja realmente excluir esta campanha?" @ok="deleteCamp(lastDeleteModalID, this)" ok-text="Excluir" />
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import table from "./HistoricTable"
import {refresh, detailsCampaign, exportarCsv, editCam, deleteCamp, pausePlay, isComplete} from "./HistoricFunctions";
import {formatDate} from "@/functions/Formatters"

export default {
  name: "Historic",
  data() {
    return {
      table,
      acoesModal: false,
      deleteModal: false,
      lastDeleteModalID: false,
    }
  },
  methods: {
    refresh,
    detailsCampaign,
    formatDate,
    exportarCsv,
    editCam,
    deleteCamp,
    pausePlay,
    isComplete
  }
}
</script>

<style scoped>
.table-campanhas .va-data-table__table-td {
  max-width: 300px;
  white-space: normal;
}
.btn-dropdown{
  min-width: 165px;
  margin-bottom: 3px;
}
</style>