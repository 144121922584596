export default {
    name: "table-buscas",
    ignorePersonalizar: true,
    novoRoute: 'painel-searchs-new',
    novoText: 'Nova Busca',
    enableAutoRefresh: true,
    autoRefreshSleepSeconds: 60,
    columns: [
        { key: 'details_id', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'nome', label: 'Nome', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'status', label: 'Status', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'termos_qty', label: 'Termos', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'leads_qty', label: 'Leads', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'criado', label: 'Data de criação', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    items: [],
}