<template>
  <div style="text-align: center; width: 45%; margin: 0 auto">
    <tr class="table-example--slot">
      <th colspan="1" >
        <va-input
          :placeholder="'Nome da lista'"
          v-model="listName"
        />
      </th>
      <th colspan="1">
        <va-button color="success" gradient @click="salvarLista(this)">
          Salvar
        </va-button>
      </th>
    </tr>
  </div>
  <va-data-table
    :no-data-html="'Sem contatos'"
    :items="items"
    :columns="columns"
    :animated="true"
    :hoverable="true"
    :striped="true"
    sticky-header
  >
    <template #headerAppend>
      <tr class="table-example--slot">
        <th
          v-for="key in Object.keys(novo)"
          :key="key"
          colspan="1"
        >
          <va-input
            :placeholder="key"
            v-model="novo[key]"
          />
        </th>
        <th colspan="1">
          <va-button
            @click="addNewItem()"
          >
            +
          </va-button>
        </th>
      </tr>
    </template>
    <template #cell(nome)="{ source: nome }">
      {{ nome }}
    </template>
    <template #cell(numero)="{ source: numero }">
      {{ numero }}
    </template>
    <template #cell(data)="{ source: data }">
      {{ data }}
    </template>
    <template #cell(actions)="{ rowIndex }">
      <va-button flat icon="delete" @click="deleteItemById(rowIndex)" />
    </template>
  </va-data-table>
</template>

<script>
import EditListTable from "./ContactListTable";
import {salvarLista} from "./ContactListFunctions"

const defaultItem = {
  name: '',
  number: '',
}

export default {
  name: "ContactList",
  data() {
    return {
      columns: EditListTable.columns,
      items: [],
      listName: '',
      id: false,
      novo: defaultItem
    }
  },
  methods: {
    salvarLista,
    deleteItemById (id) {
      this.items = [
        ...this.items.slice(0, id),
        ...this.items.slice(id + 1),
      ]
    },
    addNewItem () {
      this.items = [...this.items, { ...this.novo }]
      this.resetCreatedItem()
    },
    resetCreatedItem () {
      this.novo = { ...defaultItem }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-example--slot {
th {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
}

</style>