import {HistoricInterfaces} from "./HistoricInterfaces"

import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any, loader = true): Promise<void>
{
    const response = await FetchWs('search/get-all', 'GET', {}, {}, loader);
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertSearch(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertSearch(objs: HistoricInterfaces[]): HistoricInterfaces[]
{
    objs.forEach((value:HistoricInterfaces,key:number) => {
        objs[key].details_id = objs[key].status === 'CONCLUIDO' ? value.id : false
        objs[key].status = {
            status: objs[key].status,
            porcentagem: value.porcentagem
        }
        objs[key].usu_name = value.usu_name
    })
    return objs;
}
