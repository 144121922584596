import {UsersInterfaces} from "./UsersInterfaces";
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any): Promise<void>
{
    const response = await FetchWs('user/get-all');
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertList(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertList(objs: UsersInterfaces[]): UsersInterfaces[]
{
    objs.forEach((value:UsersInterfaces,key:number) => {
        objs[key].edit_id = value.usu_id
        objs[key].usu_name = value.usu_name
        objs[key].usu_email = value.usu_email
        objs[key].usu_data_cad = value.usu_data_cad
    })
    return objs;
}

export async function editUser(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-afiliado-users-edit', params:{id: id}});
}


export async function changePass(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-afiliado-users-pass', params:{id: id}});
}

export async function acessarUser(id: number, vue: any): Promise<void>
{
    vue.acoesModal = false;
    const response = await FetchWs('auth/acessar-user/' + id);
    if(response?.success && response?.data?.jwt) {
        if( !localStorage.getItem('JWT_TOKEN_OLD')){
            localStorage.setItem('JWT_TOKEN_OLD', localStorage.getItem('JWT_TOKEN') ?? '');
        }
        localStorage.setItem('JWT_TOKEN', response.data.jwt)
        vue.$router.push({path:'/'})
        setTimeout(()=>location.reload(),500)
    }
}

export async function editBots(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-afiliado-users-bots', params:{id: id}});
}