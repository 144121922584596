export default {
    name: "table-buscas",
    ignorePersonalizar: true,
    enableAutoRefresh: true,
    autoRefreshSleepSeconds: 60,
    columns: [
        { key: 'usu_name', label: 'Usuário', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'nome', label: 'Nome', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'status', label: 'Status', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'termos_qty', label: 'Termos', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'leads_qty', label: 'Leads', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'criado', label: 'Data de criação', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    items: [],
}