import {HistoricInterfaces} from "./HistoricInterfaces"
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any, loader = true): Promise<void>
{
    const response = await FetchWs('search/get-all', 'GET', {}, {}, loader);
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertSearch(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertSearch(objs: HistoricInterfaces[]): HistoricInterfaces[]
{
    objs.forEach((value:HistoricInterfaces,key:number) => {
        objs[key].details_id = value.id
        objs[key].status = {
            status: objs[key].status,
            porcentagem: value.porcentagem
        }
        objs[key].usu_name = value.usu_name
    })
    return objs;
}

export async function loadListas(vue: any): Promise<void>{
    const response = await FetchWs('list/get-all');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.listasDB = data;
        vue.listasSelect = data.map((v:any) => v.name);
    }
}

export async function createSearchList(vue: any): Promise<void>
{
    const bus_id = vue.modalCreateList;
    const nameList = vue.nomeNovaLista
    vue.modalCreateList = false
    vue.nomeNovaLista = ""

    const body = new FormData();
    body.append("bus_id", bus_id);
    body.append("nome", nameList);
    const response = await FetchWs('search/search-to-new-list', 'POST',body);
    if(response?.success) {
        await vue.$router.push({name: 'painel-lists'});
    }
}

export async function addListFromSearch(vue: any): Promise<void>
{
    //@todo acho que isso talvez bugue em listas com mesmo nome
    const lis_id = vue.listasDB.find((lista: any) => lista.name == vue.selectedList).id
    const bus_id = vue.modalAddList;
    vue.modalAddList = false

    const body = new FormData();
    body.append("bus_id", bus_id);
    body.append("lis_id", lis_id);
    const response = await FetchWs('search/search-to-existing-list', 'POST',body);
    if(response?.success) {
        vue.$router.push({name: 'painel-lists'});
    }
}

export async function deleteSearch(id: number, vue: any): Promise<void>
{
    const response = await FetchWs('search/delete-list/' + id, 'GET');
    if(response?.success) {
        await vue.$router.push({name: 'painel-searchs'});
    }
}

export async function playPauseSearch(id: number, vue: any): Promise<void>
{
    const response = await FetchWs('search/pause-play/' + id, 'GET');
    if(response?.success) {
        await vue.$router.push({name: 'painel-searchs'});
    }
}

export function isComplete(id: number, vue: any): boolean
{
    const searchTable = vue.$data.table.items
    // @ts-ignore
    const index = searchTable.find((value) => value.id === id)
    return index.status.status !== "CONCLUIDO"
}