<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg10 xl10">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(criado)="{ source: criado }">
            {{ formatDate(criado, 'YYYY-MM-DD H:m:i','LLL') }}
          </template>
          <template #cell(status)="{ source: status }">
            <va-chip class="chip__status" color="#ccc" v-if="status.status === 'AGUARDANDO'">Aguardando</va-chip>
            <va-chip class="chip__status" color="success" v-else-if="status.status === 'CONCLUIDO'">Finalizado</va-chip>
            <va-chip class="chip__status" color="#ccc" v-else-if="status.status === 'PAUSADO'">Pausado</va-chip>
            <va-chip v-else class="chip__status" style="margin-bottom: 5px">Processando</va-chip>
            <va-progress-bar
              v-if="status.status !== 'AGUARDANDO' && status.status !== 'CONCLUIDO' && status.status !== 'PAUSADO'"
              :model-value="status.porcentagem"
              size="large"
            >
              {{ status.porcentagem + '%' }}
            </va-progress-bar>
          </template>
          <template #cell(details_id)="{ source: details_id }">
            <va-button icon="visibility"  class="" @click="acoesModal = details_id"/>
          </template>
        <!--            <template #cell(details_id)="{ source: details_id }">-->
        <!--              <va-button v-if="details_id > 0" icon="visibility" gradient @click="detailsSearch(details_id, this)"></va-button>-->
        <!--            </template>-->
        </monta-listagem>
      </va-card-content>
    </va-card>
    <va-modal
      v-model="modalCreateList"
      hide-default-actions
    >
      <template #header>
        <h2 class="mb-2">Insira o nome da lista:</h2>
      </template>
      <va-input
        v-model="nomeNovaLista"
        placeholder="Ex.: Lista Campanha Marketing"
        label="Nome da lista"
      />
      <br>
      <br>
      <div class="row justify-center va-spacing-x-1">
        <va-button @click="modalCreateList = false" class="flex" color="secondary">
          Voltar
        </va-button>
        <va-button  class="flex" @click="createSearchList(this)">
          Adicionar
        </va-button>
      </div>
    </va-modal>
    <va-modal
      class="select__lists"
      v-model="modalAddList"
      hide-default-actions
    >
      <template #header>
        <h2 class="mb-2">Selecione a lista desejada:</h2>
      </template>
      <va-select
        label="Listas"
        v-model="selectedList"
        :options="listasSelect"
        :text-by="(option) => option.name"
        noOptionsText="Nenhuma lista encontrada"
        bordered
      />
      <br>
      <br>
      <div class="row justify-center va-spacing-x-1">
        <va-button @click="modalAddList = false" class="flex" color="secondary">
          Voltar
        </va-button>
        <va-button  class="flex" @click="addListFromSearch(this)">
          Adicionar
        </va-button>
      </div>
    </va-modal>
    <va-modal
      class="select__lists"
      v-model="modalDeleteList"
      hide-default-actions
    >
      <template #header>
        <h2 class="mb-2">Deseja excluir busca?</h2>
      </template>
      <br>
      <br>
      <div class="row justify-center va-spacing-x-1">
        <va-button @click="modalDeleteList = false" class="flex" color="secondary">
          Voltar
        </va-button>
        <va-button  class="flex" @click="deleteSearch(modalDeleteList, this);modalDeleteList = false;">
          Excluir
        </va-button>
      </div>
    </va-modal>
    <va-modal v-model="acoesModal" hide-default-actions>
      <div>
        <va-button class="btn-dropdown" icon-right="add_circle" color="success" gradient @click="modalCreateList = acoesModal;acoesModal = false;" size="medium">Adicionar a uma nova lista</va-button><br>
        <va-button class="btn-dropdown" icon-right="library_add" color="info" gradient @click="modalAddList = acoesModal;acoesModal = false;" size="medium">Adicionar a uma lista existente</va-button><br>
        <va-button class="btn-dropdown" icon-right="delete" color="danger" gradient @click="modalDeleteList = acoesModal;acoesModal = false;" size="medium">Excluir</va-button><br>
        <va-button v-if="isComplete(acoesModal,this)" class="btn-dropdown" icon-right="play_arrow" color="warning" gradient @click="playPauseSearch(acoesModal, this);acoesModal = false;" size="medium">Pausar / Processar</va-button>
      </div>
      <template #footer>
        <va-button @click="acoesModal = false" class="mr-2" color="secondary">
          Voltar
        </va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import table from "./HistoricTable"
import {
  refresh,
  createSearchList,
  addListFromSearch,
  loadListas,
  deleteSearch,
  playPauseSearch,
  isComplete
} from "./HistoricFunctions";
import {formatDate} from "@/functions/Formatters";

export default {
  name: "Historic",
  async mounted() {
    await loadListas(this);
  },
  data() {
    return {
      table,
      modalCreateList: false,
      modalAddList: false,
      modalDeleteList: false,
      nomeNovaLista: '',
      selectedList: [],
      listasSelect: [],
      acoesModal: false
    }
  },
  methods: {
    refresh,
    formatDate,
    createSearchList,
    addListFromSearch,
    deleteSearch,
    playPauseSearch,
    isComplete
  }
}
</script>


<style scoped>

.btn-dropdown{
  min-width: 300px;
  margin-bottom: 3px;
}

</style>
<style>
.select__lists .va-modal__dialog{
  overflow: visible;
}
</style>