export default {
    name: "table-campanhas",
    ignorePersonalizar: true,
    enableAutoRefresh: true,
    autoRefreshSleepSeconds: 60,
    columns: [
        { key: 'usu_name', label: 'Usuário', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'name', label: 'Nome Campanha', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'status', label: 'Status', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'start_date', label: 'Data de Início', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'shipments', label: 'Envios', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'conversions', label: 'Conversões', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cancellations', label: 'Cancelamentos', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    items: [],
}