import {HistoricInterfaces} from "./HistoricInterfaces"
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any, loader = true): Promise<void>
{
    const response = await FetchWs('campaign/get-all', 'GET', {}, {}, loader);
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertCampaign(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertCampaign(objs: HistoricInterfaces[]): HistoricInterfaces[]
{
    objs.forEach((value:HistoricInterfaces,key:number) => {
        objs[key].details_id = value.id
        objs[key].usu_name = value.usu_name
        objs[key].name = value.name
        objs[key].start_date = value.start_date
        objs[key].shipments = value.shipments
        objs[key].conversions = value.conversions
        objs[key].cancellations = value.cancellations
        objs[key].status = {
            statusName: getStatus(value.status, value.start_date),
            percent: value.statusPercent
        }
    })
    return objs;
}

export async function detailsCampaign(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-campaign-details', params:{id: id}});
}

function getStatus(status: any, data_inicio: string){
    const dataInicio = new Date(data_inicio);
    const dataAgora = new Date();

    if(status === 'COMPLETE'){
        return 'Completo';
    }else if(status === 'PROCESSING'){
        if(dataInicio <= dataAgora){
            return 'Processando';
        }
        if(dataInicio >= dataAgora){
            return 'Aguardando';
        }
    }else if(status === 'PAUSED'){
        return 'Pausado';
    } else{
        return 'Sem status';
    }
}