import {HistoricInterfaces} from "./HistoricInterfaces"
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any, loader = true): Promise<void>
{
    const response = await FetchWs('campaign/get-all', 'GET', {}, {}, loader);
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertCampaign(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertCampaign(objs: HistoricInterfaces[]): HistoricInterfaces[]
{
    objs.forEach((value:HistoricInterfaces,key:number) => {
        objs[key].edit_id = value.id
        objs[key].usu_name = value.usu_name
        objs[key].name = value.name
        objs[key].start_date = value.start_date
        objs[key].shipments = value.shipments
        objs[key].conversions = value.conversions
        objs[key].cancellations = value.cancellations
        objs[key].status = {
            statusName: getStatus(value.status, value.start_date),
            percent: value.statusPercent
        }
    })
    return objs;
}

export async function detailsCampaign(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-campaign-details', params:{id: id}});
}

function getStatus(status: any, data_inicio: string){
    const dataInicio = new Date(data_inicio);
    const dataAgora = new Date();

    if(status === 'COMPLETE'){
        return 'Completo';
    }else if(status === 'PROCESSING'){
        if(dataInicio <= dataAgora){
            return 'Processando';
        }
        if(dataInicio >= dataAgora){
            return 'Aguardando';
        }
    }else if(status === 'PAUSED'){
        return 'Pausado';
    } else{
        return 'Sem status';
    }
}

export async function exportarCsv( vue: any, cam_id: string) {


    const response = await FetchWs('campaign/export/' + cam_id, 'GET');
    if(response && response.success && Array.isArray(response.data)){
        const items: Array<{origem: string, number: string, name: string, status: string, shot_date: string}> = response.data
        const csvString = [
            [
                "Origem",
                "WhatsApp",
                "Nome/URL",
                "Status",
                "Data do Disparo"
            ],
            ...items.map((item) => [
                item.origem,
                item.number,
                item.name,
                item.status,
                item.shot_date
            ])
        ]
            .map(e => e.join(","))
            .join("\n");
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `Campanha-${cam_id}.csv`);
        a.click()
    } else {
        return true;
    }
}

export async function editCam(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-campanhas-edit', params:{id: id}});
}

export async function deleteCamp(id: number, vue:any): Promise<void>
{
    const response = await FetchWs('campaign/delete/' + id, 'GET');
    if(response?.success) {
        vue.$router.back()
    }
}

export async function pausePlay(vue:any, id: number): Promise<void>
{
    const statusCampaign = await FetchWs('campaign/pause-play/' + id, 'GET');
    if(statusCampaign.success) {
        await refresh(vue)
    }
}

export function isComplete(id: number, vue: any): boolean
{
    const campaignsTable = vue.$data.table.items
    // @ts-ignore
    const index = campaignsTable.find((value) => value.id === id)
    return index.status.statusName !== "Completo"
}